export default function TaxiQuickBooking(){
     return(<>
         <div className="card-group shadow-sm">
             <div className="card border-0 p-0 m-0">
                 <div className="card-body p-0 m-0">
                     <section className="text-center bg-info bg-gradient p-3">
                         <p className="fs-1 fw-bold">For Quick</p>
                         <p className="fs-1 fw-bold">Cab BOOKING</p>
                     </section>
                 </div>                 
             </div>
             <div className="card border-0 p-0 m-0">
                 <div className="card-body p-0 m-0">
                     <section className="text-center bg-info bg-gradient p-3">
                         <p className="fs-1 fw-bold">Call/Whatsapp</p>
                         <p className="fs-1 fw-bold"> (+91) 9531393648</p>
                     </section>
                 </div>                 
             </div>
         </div>
     </>);
}