export default function TaxiServiceDisplay(){
     return(<>
            
           <div className="card-group font font24 shadow rounded border border-warning z-0">                                    
                <div className="card">
                     <img src="https://i.ibb.co/kXg560P/localcab.png" className="card-img-top img-fluid" alt="local taxi"/>
                </div>
                <div className="card">
                     <img src="https://i.ibb.co/Z87d0Lq/outstationcab.png" className="card-img-top img-fluid" alt="outstation taxi"/>
                </div>
                <div className="card">
                     <img src="https://i.ibb.co/j5g0hmY/airportcab.png" className="card-img-top img-fluid" alt="airport taxi"/>                                             
                </div>
                <div className="card">
                     <img src="https://i.ibb.co/6yZwQKj/corporatecab.png" className="card-img-top img-fluid" alt="corporate cab"/>                                             
                </div>
                <div className="card">
                     <img src="https://i.ibb.co/hFW2x50/sightseeingcab.png" className="card-img-top img-fluid" alt="sightseeing cab"/>                                             
                </div>                                                                        
           </div>                     
     </>);
}